@import '../../themes/variables';

#detalhesBlog {
  color: $second-theme-color;
  font-family: 'Poppins-Regular';

  .carouselBox {
    .img {
      width: 100%;
      height: 400px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .header {
    margin-top: 2rem;
    color: $primary-color;

    .categoria {
      font-size: $fonte-pequena;
      margin-bottom: 2rem;
    }
    .titulo {
      font-family: 'Poppins-Bold';
      font-size: $fonte-exgigante;
      line-height: $fonte-exgigante;
    }
  }

  .body {
    margin-top: 2rem;
    color: $primary-color;
    font-size: $fonte-media;
  }

  .arquivos {
    color: $primary-color;
    margin-top: 2rem;
    .arquivosTitulo {
      margin-bottom: 1rem;
      padding-bottom: 5px;
      font-size: $fonte-exmedia;
      font-family: 'Poppins-Bold';
      border-bottom: 2px solid $second-theme-color;
    }

    .arquivo {
      margin-bottom: 10px;
      a {
        display: flex;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        color: $primary-color;
        font-size: $fonte-media;
        font-family: 'Poppins-Regular';
        .downloadIcon {
          margin-right: 10px;
          width: 35px;
          height: 25px;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }
  }
}

@media(max-width: 991.98px) {
  #detalhesBlog {
    .carouselBox {
      margin-bottom: 2rem;
      .carousel-indicators {
        bottom: -55px;

        & [data-bs-target] {
          background-color: $gray-color;
          height: 15px;
          width: 15px;
          border-radius: 50%;
          margin-right: 12px;
          margin-left: 12px;
        }
      }

      .active {
        background-color: $second-theme-color !important;
      }
    }
  }
}



// ------------------- skeletons ----------------------

#detalhesBlog {
  .skeletonCarouselDetalhesBlog {
    width: 100%;
    height: 400px;
  }

  .skeletonTituloDetalhesBlog {
    width: 100%;
    height: 80px;
  }

  .skeletonCategoriaTagDetalhesBlog {
    height: 21px;
    width: 100px;
    margin-bottom: 2rem;
  }

  .skeletonCategoriaTagDetalhesBlogContainer {
    margin-top: 2rem;
    width: 100%;
    .skeletonCategoriaTagDetalhesBlog {
      height: 160px;
      width: 100%;
      margin-bottom: 2rem;
    }
  }
}