@import '../../themes/variables';

#paginaHome {
  min-height: 150vh;
}

@media (min-width: 991.98px){
  #paginaHome {

    &::-webkit-scrollbar {
      display: none;
    }

    .ancora-home {
      width: 10px;
      height: 0px;
    
      &#ancora-home-0 {
        height: 0px !important;
      }
    }  
  }

}