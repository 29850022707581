@import '../../themes/variables';

.garantia-produto{
    .listItemP{
        border: none;
        background-color: #f4f4f5;
    }

    .listItemS{
        border: none;
        background-color: #f4f4f5;
        padding-left: 30px;
    }
}