@import '../../themes/variables';

#detalhesProduto {
  color: $second-theme-color;
  padding-top: $padding-page-top;
  padding-bottom: $padding-page-bottom;
  min-height: 100vh;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  .boxImagens {
    .destaque {
      cursor: zoom-in;
      border: 1px solid $gray-color;
      width: 100%;
      height: 400px;
    }
    .carouselBox {
      margin-top: 1rem;
      position: relative;

      .iconBtn {
        z-index: 1;
        height: 25px;
        width: 25px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        fill: gray;

        &.iconPrev {
          left: -5px;
        }

        &.iconNext {
          right: -5px;
        }
      }

      .carousel-indicators {
        display: none;
      }

      .carousel-control-prev, .carousel-control-next {
        width: 5%;
        span {
          display: none;
        }
      }

      .miniImagem {
        
        cursor: pointer;
        height: 100px;
        opacity: 0.5;

        &:hover {
          border: 1px solid $gray-color;
          opacity: 1;
        }
      }
    }
  }

  .boxDetalhes {

    .boxTitulo {
      background-color: $shadow-color;
      padding: 1rem 15px;
      .titulo {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
        font-size: $fonte-gigante;
        // line-height: $fonte-gigante;
        // height: calc($fonte-gigante * 2);
        color: $primary-color;
        font-family: 'Poppins-Bold';
      }

      .favoritoBotao {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 15px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .descricao {
      background-color: $shadow-color;
      padding: 1rem 15px;
      width: 100%;
      font-size: $fonte-media;
      font-family: 'Poppins-Regular';
      color: $primary-color;
    }

  }
  

  .boxEspecificacoes {
    .tituloEspecificaoes {
      padding: 5px 0;
      border-bottom: 1px solid $second-theme-color;
      color: $primary-color;
      font-family: 'Poppins-Bold';
      font-size: $fonte-gigante;
    }

    .especificacoes {
      color: $primary-color;
      font-family: 'Poppins-Regular';
    }
  }




  @media (max-width: 991.98px) {
    .boxDetalhes {
      padding-top: 1rem;
      .boxTitulo {
        position: relative;
        .favoritoBotao {
          right: 0 !important;
        }
      }
    }
  }

  .accordion {
    
    .accordion-item {
      margin-top: 15px;
      border: none;
      border-radius: 10px;
      background-color: $shadow-color;

      .accordion-header {

        .tituloEIcone {
          font-family: 'Poppins-Bold';
          display: flex;
          align-items: center;

          .iconeTitulo {
            margin-right: 1rem;
            width: 30px;
            height: 30px;
            position: relative;

            fill: $second-theme-color;

            img {
              object-fit: fill;
            }

            img.sucessoImg {
              position: absolute;
              width: 40%;
              height: 40%;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }

        button {
          color: $primary-color;
          background-color: transparent;
          border-radius: 0;
          display: flex;
          justify-content: space-between;
          box-shadow: none;
          text-transform: uppercase;

          &.collapsed {
            .up {
              display: none;
            }
          }

          &:not(.collapsed) {
            .down {
              display: none;
            }
          }

          &::after {
            display: none;
          }

        }
      }

      .accordion-collapse {
        .accordion-body {
          font-family: 'Poppins-Regular';
          padding: 2rem 1rem;
          color: $primary-color;
        }
      }
    }

    .arquivoAccordionBody {
      a {
        text-decoration: none;
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 10px;
        color: $primary-color;
        font-size: $fonte-media;
        .iconeLink {
          margin-right: 1rem;
          width: 30px;
          height: 25px;
          position: relative;

          fill: $second-theme-color;

          img {
            object-fit: contain;
          }

          img.sucessoImg {
            position: absolute;
            width: 40%;
            height: 40%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }

  @media (min-width: 991.99px) {
    .rowDetalhes {
      position: relative;
      .boxDetalhes {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}


#detalhesProduto {

  .skeletonDestaque {
    height: 515px;
  }

  .skeletonDetalhes {
    height: 515px;
    .titulo {
      height: 20%;
    }
    .descricao {
      padding-top: 10px;
      height: 60%;
    }

    .accordion {
      padding-top: 10px;
      height: 10%;
    }
  }

  .skeletonEspecificacoes {
    margin-top: 2rem;
    height: 700px;
  }

  @media (max-width: 991.98px) {
    .skeletonDetalhes {
      margin-top: 2rem;
    }
  }

}