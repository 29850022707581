@import '../../../themes/variables';

#sessaoSobreNos {
  color: $second-theme-color;
  padding-top: 80px;
  position: relative;

  .containerLoading {
    .videoLoading {
      aspect-ratio: 1.4;
    }

    .containerEscrita {
      display: flex;
      justify-content: center;
      flex-direction: column;
      .tituloLoading {
        width: 200px;
        margin-bottom: 30px;
        height: 50px;
      }
      .containerTextLoading{
        margin-bottom: 15px;
        .textoLoading{
          max-width: 500px;
        }
      }
      .botaoLoading{
        width: 190px;
        height: 40px;
      }
    }
  }

  .boxExterno {
    position: relative;
  }

  .main {
    display: flex;
    justify-content: flex-end;

  }

  .noGutters {
    --bs-gutter-x: 0;
  }

  .box1 {
    position: absolute;
    left: 0;
    height: 75vh;

    .mediaBox {
      position: relative;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;

      .boxButton {
        padding: 10px 10px 10px 12px;
        background-color: $second-theme-color;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 990;
        border-radius: 100%;
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        animation: pulse 2.5s linear infinite;

        &:hover {
          animation: none;
        }

        .playButton {
          height: 80%;
          width: 80%;
          fill: $theme-color;
        }
      }
    }
  }

  .box2 {
    color: $primary-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // min-height: 400px;
    // height: 75vh;

    .tag {
      width: 6px;
      height: 2rem;
      background-color: $second-theme-color;
      margin-bottom: 1rem;
    }

    .titulo {
      font-family: 'Poppins-Bold';
      font-size: $fonte-exgrande;
      margin-bottom: 1rem;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    .description {
      margin-bottom: 0;
      color: $third-color;

      .paragrafo {
        font-size: $fonte-media;
        p {
          font-family: 'Poppins-Regular';
        }

        p::first-letter {
          text-transform: capitalize;
        }
      }
    }

    .saibaMais .saibaMaisButton {
      // width: fit-content;
      font-family: 'Poppins-Regular';
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 10px;
      background-color: $theme-color;
      border: 1px solid $second-theme-color;
      color: $second-theme-color;
      font-size: $fonte-expequena;

      span {
        text-transform: uppercase;
      }

      &:hover {
        background-color: $second-theme-color;
        color: white;
      }

      .arrowRight {
        margin-left: 5px;
        width: 25px;
        height: 15px;
      }
    }

  }


}

.sobreNosModal {
  .modal-content {
    background-color: black;

    .modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .closeModal {
      fill: $second-color;
      position: absolute;
      right: 30px;
      top: 30px;
      z-index: 1100;
      cursor: pointer;
    }

  }
}

@media(max-width: 991.98px) {
  #sessaoSobreNos {
    
    .containerLoading {
      .videoLoading {
        margin-bottom: 30px;
      }
  
      .containerEscrita {
        .containerTextLoading{
          .textoLoading{
            max-width: 100%;
          }
        }
        .botaoLoading{
          width: 100%;
        }
      }
    }

    .box1 {
      position: relative !important;
      height: 300px !important;
    }

    .box2 {
      margin-top: 50px;

      &>* {
        margin-bottom: 1rem;
      }

      .saibaMais {
        margin-top: 15px;

        .saibaMaisButton {
          width: 100%;
        }
      }
    }
  }
}

@media(min-width: 991.99px) {
  #sessaoSobreNos {
    .containerLoading {
      padding: 0;
    }
    .box1 {
      padding-right: 30px;
      height: 100%;
      padding-left: 0;
    }

    .box2 {
      .saibaMaisButton {
        width: 30%;
      }
    }
  }

  // review
  #sessaoSobreNos.resumo {
    .boxExterno {
      height: calc(100vh - 130px);
      display: flex;
      align-items: center;
  
      .box1, .box2 {
        height: calc(90vh - 130px);
      }
  
      .box2 {
        flex-direction: column;
        justify-content: space-around;
        display: flex;
  
        .description {
          margin-top: 1rem;
          overflow: hidden;
          font-size: $fonte-pequena;
  
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 7; /* number of lines to show */
          -webkit-box-orient: vertical;
          line-height: calc($fonte-pequena + 5px);
          height: calc(($fonte-pequena + 5px) * 7);
        }
  
        .saibaMais {
          margin-top: 2rem;
          margin-bottom: 0;
        }
      }
    }
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(white, 1);
  }

  70% {
    box-shadow: 0 0 0 0px rgba(white, 1);
  }

  100% {
    // transform: scale(1.5);
    box-shadow: 0 0 0 25px rgba(white, 0);
  }
}