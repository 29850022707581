@import "../../../themes/variables";

#sessaoHeaderSide {
  position: relative;
  z-index: 1000;

  &.transparent {
    .navbar {
      background-color: transparent;
    }
  }

  &.opaque {
    .navbar {
      background-color: $third-theme-color;
      height: 130px;
    }
  }

  .navbar {
    &.fixed-top {
      top: auto;
    }

    .img {
      height: 80px;
      width: 160px;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    .offcanvas-body {
      font-family: 'Poppins-Bold';
      display: flex;
      flex-direction: row !important;
      align-items: center;
      justify-content: flex-end;

      &>*,
      .icon {
        margin: 0 10px;
      }

      .icons {
        display: flex;

        // min-width: 62px;
        .icon {
          fill: $second-color;
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }

      .linkA {
        color: $second-color;
        text-decoration: none;
        font-weight: normal;
        cursor: pointer;
        position: relative;
        white-space: nowrap;
        font-size: $fonte-pequena;

        .acordionBody {
          padding: 0;
          // min-height: 410px;

          .listaDeProdutos {
            list-style-type: none;
            padding: 0;
            width: 332px;
            position: relative;
            margin: 0;

            .produto {
              padding: 7px 15px;
              font-size: 14px;

              &:hover {
                background-color: rgb(241, 241, 241);
              }

              .link {
                width: 100%;
                text-decoration: none;
                color: black;

                .nome {
                  // text-transform: capitalize;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  white-space: break-spaces;

                }
              }


              .containerCategorias {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                transition-delay: 0.15s !important;
                transition: .5s;
                background-color: white;
                opacity: 0;
                visibility: hidden;
                padding: 0 15px;
                background-color: #f2f2f2;

                .titulo {
                  display: none !important;
                  font-weight: bold;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  text-decoration: underline;
                  white-space: break-spaces;
                }

                .subTitulo {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  .link {
                    text-decoration: none;
                    color: grey;

                    .nome {
                      font-family: 'Poppins-Regular';
                      padding: 5px 0;
                      white-space: break-spaces;
                      color: black;
                    }
                  }
                }

              }

              &:hover .containerCategorias {
                opacity: 1;
                transform: translate(332px);
                visibility: visible;
              }
            }
          }
        }

        & .accordion-button {
          font-size: $fonte-pequena;
        }

        .linkC {
          font-family: 'Poppins-Regular';
          width: 100%;
        }

        .optionB {
          margin: 0 20px;
        }

        .first {
          margin-left: 0;
        }

        .last {
          margin-right: 0;
        }

        .accordion-header {
          border: 0;

          .expandIcon,
          .recallIcon {
            fill: $second-color;
            margin-left: 5px;
          }
        }

        .accordion-button {
          background-color: transparent;
          color: $second-color !important;
          border-radius: 0;
          box-shadow: none;

          &[aria-expanded="true"] {
            .expandIcon {
              display: none;
            }
          }

          &[aria-expanded="false"] {
            .recallIcon {
              display: none;
            }
          }
        }

        .accordion-item {
          border: 0;
          background-color: transparent;
        }

        .accordion-button::after {
          display: none;
        }

        .accordion-collapse {
          position: absolute;
          transform: translate(-70%, 10px);
        }

        .accordion-body {
          display: flex;
          background-color: $theme-color;
        }

        .linkB {
          background-color: $theme-color;
          display: flex;
          flex-direction: column;
          text-transform: uppercase;
          margin: 5px 10px;
          cursor: default;

          span {
            margin-bottom: 15px;
            color: $primary-color;
          }

          .linkC {
            color: $third-color;
            text-transform: uppercase;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
    }

    // offcanvas-body
  }
}

.offcanvas {
  background-color: $third-theme-color;

  .offcanvas-body {
    display: flex;
    flex-direction: column !important;

    &>* {
      margin: 5px 0;
      text-decoration: none;
      color: $second-color;
      // text-transform: uppercase;
    }

    .linkA {
      font-family: 'Poppins-Bold';
      margin-bottom: 7px;
    }

    .linkB {
      margin-bottom: 15px;

      span {
        color: $second-color;
      }

      div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .linkC {
          width: 50%;
          text-transform: inherit;
          text-decoration: none;
          color: $third-color;
          font-family: 'Poppins-Regular';
        }
      }
    }

    .icon {
      margin-right: 10px;
      width: 25px;
      height: 25px;
    }

    .accordion-item {
      border: 0;
      background-color: transparent;
    }

    .accordion-button {
      padding: 0;
      text-transform: uppercase;
      background-color: transparent;
      color: $second-color;
      border-radius: 0;
      box-shadow: none;

      &::after {
        display: none;
      }

      &[aria-expanded="true"] {
        .expandIcon {
          display: none;
        }
      }

      &[aria-expanded="false"] {
        .recallIcon {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  #sessaoHeaderSide {
    &.opaque {
      .navbar {
        background-color: $third-theme-color;
        height: 100px;
      }
    }
  }

  .icons {
    border-top: 1px solid gray;
    display: flex;
    justify-content: space-between;
    margin-top: auto !important;
    padding-top: 20px;
  }

  .acessoRapido {
    display: flex;
    flex-direction: column;
    margin: auto 0 !important;

    &>* {
      color: $second-color;
      text-decoration: none;
      margin: 7px 0;
    }
  }

  .linkA.lastA {
    margin-bottom: 30px !important;
  }

  .linkA {
    .acordionBody {
      padding: 15px;

      .listaDeProdutos {
        padding: 0;

        .produto {
          margin: 15px 0;

          .link {
            text-decoration: none;

            .nome {
              .icone {
                display: none;
              }
            }
          }

          .containerCategorias {
            display: block;
            padding: 0;
            padding-left: 15px;
            margin-top: 5px;

            .titulo {
              display: none;
            }

            .subTitulo {
              text-align: center;
              margin: 15px 0;

              .link {
                .nome {
                  color: gray;
                  font-weight: normal;
                }
              }
            }

          }

          &:hover .containerCategorias {}
        }
      }
    }
  }

  .navbar .img {
    padding: 10px 0 !important;
  }

  .expandIcon,
  .recallIcon {
    margin-left: 0.5rem;
  }

  .offcanvas {
    .linkC {
      margin-top: 15px;
    }
  }

  a {
    cursor: pointer;
    color: $second-color;
  }
}

@media (min-width: 991.99px) {
  .acessoRapido {
    display: none;
  }

  .hambugger {
    display: none;
  }

  .containerCategorias {
    height: fit-content;
    max-height: 310px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0.3em;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $second-theme-color;
    }
  }
}

.hambugger {
  border: none;
  background-color: transparent;

  &>* {
    color: $second-color;
    width: 45px;
    height: 45px;
  }
}

.handler {
  border: none;
  background-color: transparent;

  &>* {
    color: $second-color;
    width: 25px;
    height: 25px;
  }
}

#headerBox {
  position: absolute;
  width: 100%;
}

// ------------- skeletons styles ---------------

#sessaoHeaderSide {
  .imageSkeleton {
    width: 160px;
    height: 80px;
  }

  .OptionSkeleton {
    width: 120px;
    height: 21px;
  }

  .IconsMenuSkeleton {
    width: 70px;
    height: 42px;
  }

  @media (max-width: 991.98px) {
    .imageSkeleton {
      width: 160px;
      height: 80px;
    }

    .OptionSkeleton {
      width: 120px;
      height: 21px;
    }

    .IconsMenuSkeleton {
      width: 70px;
      height: 42px;
    }
  }
}