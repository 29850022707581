


#body-cookie-politica-privacidade {
    position: fixed;
    bottom: 0;
    z-index: 9999;
    background-color: #FFFFFF;

    .btn-politica-privacidade a {
        background: #2da352;
        color: #FFFFFF;
        padding: 10px 30px;
        display: block;
    }


    .row-lgpd {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        padding: 10px 0;
    }

    .descricao-politica-privacidade {
        width: 60%;
        font-size: 12px;
    }
    .btn-politica-privacidade {
        
    }

}

@media (max-width: 991.98px) {
    #body-cookie-politica-privacidade {
        .descricao-politica-privacidade {
            width: 100%;
        }
        .row-lgpd {
            flex-direction: column;
            // padding-bottom: 50px;
        }
        .btn-politica-privacidade {
            margin-left: 70%;
            font-size: 12px;
            width: 30%;
            margin-top: 20px;
        }
    }
}

.politica {
    color: #007bff;
}