@import '../../../themes/variables';

#sessaoAcessoRapido {
  background-color: transparent;
  color: $second-theme-color;
  width: 100%;

  position: relative;
  z-index: 1000;

  .box {
    padding: 20px 0 10px;
    border-bottom: 1px solid gray;

    .leftBox {
      display: flex;
      justify-content: flex-start;
    }

    .rightBox {
      display: flex;
      justify-content: flex-end;
    }
  }

  // .iconBox {
  //   display: flex;
  //   color: $theme-color;
  //   align-items: center;
  // }

  a,
  .iconBox {
    font-family: 'Poppins-Bold';
    font-size: small;
    margin: 10px;
    color: $second-color;
    text-decoration: none;

    .icon {
      margin: 0 0.5rem;
      width: 20px;
      height: 20px;
    }
  }
}

@media(max-width: 991.98px) {
  #sessaoAcessoRapido {
    .box {
      // display: none;
      border: none;

      .iconBox {
        margin: 10px 0;
        a {
          margin: 0;
        }
      }

      .leftBox {
        padding-left: 0 !important;
        display: flex;
        flex-direction: column;
      }
    }


  }
}


// -------- skeletons styles ------------

#sessaoAcessoRapido {
  .skeletonAcessoRapido {
    margin-top: 10px;
    width: 100%;
    height: 60px;
  }
}


@media(max-width: 991.98px) {
  .skeletonAcessoRapido {
    // display: none;
  }
}