@import '../../../themes/variables';

#sessaoSubcategorias {
   color: $second-theme-color;
   min-height: 100px;
   padding-top: $padding-page-top;
   padding-bottom: $padding-page-bottom;

   .rowSubcategoria {
      padding-bottom: 40px;
      padding-top: 40px;
      .subcategoriaTitulo {
         font-family: 'Poppins-Bold';
         margin-bottom: 10px;
         span {
            font-size: $fonte-gigante;
            color: $primary-color;
         }
      }
   }

   .produtosCardCarousel {
      .carousel-control-prev {
         justify-content: start;
         left: -15px;
      }

      .carousel-control-next {
         justify-content: end;
         right: -15px;
      }

      .carousel-control-next, .carousel-control-prev {
         opacity: 1;
         height: 70%;
         top: 50px;
         span {
            background-color: $second-theme-color;
            border: 3px solid $second-theme-color;
         }
      }
   }

   .linkVejaMais {
      text-decoration: none;
      cursor: pointer;
   }
   .vejaMais {
      width: 100%;
      height: 100%;
      border: 1px solid $gray-color;
      display: flex;
      align-items: center;
      justify-content: center;
      .caixa {
         width: 60%;
         display: flex;
         align-items: center;
         flex-direction: column;

         span {
            color: $primary-color;
            font-size: $fonte-media;
            text-align: center;
            font-family: 'Poppins-Bold';
         }

         .linkBotao {
            margin-top: 10px;
            background-color: $second-theme-color;
            padding: 2px 15px;

            .icon {
               width: 30px;
               height: 30px;
               fill: white;
            }
         }
      }
   }
}

@media (max-width: 991.98px) {
   #sessaoSubcategorias {
      .produtosCardCarousel {
         .carousel-indicators {
            bottom: -55px;
            button {
               width: 12px;
               height: 12px;
               border-radius: 50%;
               background-color: $gray-color;
               margin-right: 10px;
               margin-left: 10px;

               &.active {
                  background-color: $second-theme-color;
               }
            }
         }

         .carousel-control-next, .carousel-control-prev {
            display: none;
         }
      }
      .rowSubcategoria {
         display: flex;
         flex-direction: column;
         .subcategoriaTitulo {
            order: 1;
         }
         .colSubcategoria {
            order: 3
         }
         .colVejaMais {
            order: 2;
            margin-bottom: 15px;
            .vejaMais {
               height: fit-content;

               .caixa {
                  flex-direction: row;
                  align-items: center;

                  .linkBotao {
                     margin-top: 0;
                  }
               }
            }
         }

      }
      .vejaMais .caixa span {
         margin-right: 10px;
      }
   }
}

// --------- skeletons styles ---------

.skeletonSubcategoriaSessao {
   padding-top: $padding-page-top;
   padding-bottom: $padding-page-bottom;
   .rowSkeletonSubcategoriaSessao {
      margin-top: 30px;
      .titulo {
         margin-bottom: 5px;
      }
   }
}

.skeletonSubcategoria {
   width: 100%;
   height: 352px;
}


@media (max-width: 991.98px) {
   .skeletonSubcategoria {
      height: 462px;
   }

   .skeletonSubcategoriaSessao {
   
      .skeletonVejaMais {
         display: none;
      }
   }
}