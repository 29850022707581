@import '../../../themes/variables';

#sessaoMaps {
  padding: 0;

  .maps {
    width: 100%;
    height: 350px;
  }

  .itemMaps { 
    padding: 0;
  }

}

@media (max-width: 991.98px) {
  .itemMaps {
    padding: 0;
  }
}