@import "../../../themes/variables";

#SessaoInformacoesContato {
  padding: 80px 0 0 0;

  .itemContatoInfo {
    text-align: center;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    padding: 20px;
    border-radius: 5px;
    height: 190px;
    align-content: center;
    display: grid;

    .icon-text {
      font-size: $fonte-media;
      color: $second-theme-color;
      font-weight: bold;
      margin-right: 7px;
    }

    .icon {
      width: 22px !important;
      height: 22px !important;
      margin-bottom: 10px;
      color: $second-theme-color;

      &.phone {
        margin-right: 7px;
      }
    }

    .textoInfo {
      font-family: 'Poppins-Regular';
    .link{
      text-decoration: none;
      p {
        margin-bottom: 0 !important;
        font-size: $fonte-media;
        color: black;
      }
    }
    }
  }

  @media (max-width: 991.98px) {
    #SessaoInformacoesContato {
      .colContato {
        padding: 10px;
      }
    }
  }
}
