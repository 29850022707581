@import "../../themes/variables";

#menuListagemProdutos {
  color: $primary-color;

  .toggleHeaderContainer {
    position: relative;
    cursor: pointer;
    .chevrom {
      position: absolute;
      right: 0;
      top: 4px;
      transform: translate(-100%, 0%);
      transition: all 0.3s;

      &.down {
        transform: translate(-100%, 0%) rotate(180deg);
      }
    }
  }

  .containerCaracteristicasSelecionadas {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    margin-bottom: 30px;
    .caracteristicaSelecionada {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      background-color: gray;
      color: white;
      padding: 5px 10px;
      border-radius: 10px;
      opacity: 0.9;
      .iconeFechar{
        cursor: pointer;
      }
    }
  }

  .menu {
    margin-bottom: 15px;
    padding-bottom: 15px;

    input[type="checkbox"] {
      accent-color: $second-theme-color;
    }

    .titulo {
      font-family: "Poppins-Bold";
      margin-bottom: 10px;
    }

    .option {
      margin-top: 5px;
      display: flex;
      input {
        margin-right: 5px;
      }
      label {
        font-family: "Poppins-Regular";
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }

  .resetarBotao {
    width: 100%;
    height: 40px;
    background-color: $theme-color;
    color: $second-theme-color;
    border: 2px solid $second-theme-color;
    margin-bottom: 30px;
    transition: all 0.3s;
    &:active:not([disabled]) {
      color: white;
      background-color: $second-theme-color;
    }
  }
}

#menuListagemProdutos {
  .skeletonMenu {
    height: 120px;
    margin-bottom: 15px;
  }
}
