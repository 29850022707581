@import "../../themes/variables";

.titulo-banner {
  position: absolute;
  bottom: 250px;
  left: 100px;
  opacity: 0.8;
  max-width: 700px;
  min-width: 350px;
  padding: 14px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-family: "Poppins-Bold";
    font-size: 22px;
    margin: 0;
    color: white;
    background: #096106;
    opacity: 0.95;
    padding: 5px 10px;
    margin-bottom: 10px;
  }
}


#banner {
  color: $second-color;
  position: relative;

  &.carregando {
    background-color: #dddddd;
  }

  .imagem {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    filter: brightness(0.65);
  }

  .sombra {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .bannerLoading {
    width: 100%;
  }

  .bannerLoading.normal {
    aspect-ratio: 1;
  }

  .bannerLoading.contido {
    aspect-ratio: 3.4;
  }

  .bannerLoading.interna {
    aspect-ratio: 5;
  }

  &.contido {
    margin-top: 130px;
  }

  .caixa {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;

    a {
      text-decoration: none;
      color: $second-color;
    }

    .detalhes {
      .title {
        font-size: $fonte-exgigante;
        font-family: 'Poppins-Bold';
        text-transform: uppercase;
      }

      .breadcrumbs {
        font-size: $fonte-media;
        font-family: 'Poppins-Regular';
        text-transform: uppercase;

        .separador {
          margin: 0 0.5rem;
        }
      }

      .desc {
        font-family: 'Poppins-Regular';
        font-size: $fonte-pequena;
      }
    }
  }
}

@media (max-width: 991.98px) {

  .titulo-banner {
    left: 0;
    bottom:0;
    p {
      font-size: 14px;
      margin-bottom: 4px;
      padding: 5px 13px;
    }
  }

  #banner {
   
    display: flex;
    .imagem {
      width: 100%;
      left: 0;
    }
    .bannerLoading.normal {
      aspect-ratio: 1.3;
    }

    .bannerLoading.contido {
      aspect-ratio: 0.85;
    }

    .bannerLoading.interna {
      aspect-ratio: 1.3;
    }
    &.normal {
      height: 300px;
    }

    &.interna {
      height: 300px;

      .caixa .detalhes {
        position: absolute;
        bottom: 0;
        padding-bottom: 30px;
        .title {
          font-size: $fonte-gigante;
        }
        .breadcrumbs {
          font-weight: normal !important;
        }
      }
    }
    .caixa {
      padding-right: 30px;
      .detalhes {
        .title {
          font-size: $fonte-gigante;
        }
        .breadcrumbs {
          font-weight: normal !important;
        }
        .desc {
          display: none;
        }
      }
    }

    &.contido {
      margin-top: 100px;
      .detalhes {
        padding: 30px 10px 10px;

        .breadcrumbs {
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.desc {
  p {
    margin:0;
  }
}
@media (min-width: 991.99px) {
  #banner {
    min-height: 100px;

    &.normal {
      height: 100vh;
      
      .caixa {
        width: 65%;
        height: auto;
        display: flex;
        justify-content: center;
        
        .desc {
          padding: 485px 0px 50px 0px;
          margin-top: 110px;
          display: flex;
          align-items: center;
          font-size: 24px;
        }
      }
    }

    &.interna {
      height: 380px;

      .caixa {
        width: 100%;
        display: flex;
        justify-content: center;

        .detalhes {
          width: 100%;
          padding: 30px 0px 50px 12px;
          position: absolute;
          bottom: 0;
        }
      }
    }

    &.contido .caixa {
      .detalhes {
        padding: 50px 20px 30px;
        width: 100%;

        .breadcrumbs {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
