@import "../../../themes/variables";

#sessaoFooter {
  background-color: $primary-color;
  padding: 90px 0 20px 0;
  position: relative;
  margin-top: 130px;

  .itemRodape {
    .imgRodape {
      text-align: center;

      .logo {
        width: 55%;
      }
    }

    .iconesRodape {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 10px;

      a {
        color: white;

        .iconeRedeSociais {
          width: 22px;
          height: 22px;
        }
      }
    }
  }

  .itemRodape {
    color: $theme-color;

    .tituloRodape {
      font-family: "Poppins-Bold";
      font-size: $fonte-exmedia;
      text-transform: uppercase;
    }

    .textoRodape {
      
      font-family: "Poppins-Regular";
      font-size: $fonte-media;
      a {
        text-decoration: none;
        color: white;
      }

      li {
        list-style-type: none;
        margin-bottom: 5px;
        font-size: 12px;
        text-transform: uppercase;
      }
      .email {
        li {
          text-transform: none;
        }
      }
      .icon {
        color: $second-theme-color;
      }
    }
  }

  .responsivo {
    display: none;
  }
}

@media (max-width: 991.98px) {
  #sessaoFooter {
    margin-top: 0;
    .container {
      .desktop {
        display: none;
      }

      .responsivo {
        display: block;

        .itemRodape {
          .imgRodape {
            text-align: center;

            .logo {
              padding: 8px 25px;
              width: 60%;
            }
          }

          .iconesRodape {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            padding: 12px;

            a {
              color: white;

              .iconeRedeSociais {
                width: 22px;
                height: 22px;
              }
            }
          }

          .accordion {
            --bs-accordion-bg: $primary-color;
            --bs-accordion-btn-color: $second-color;

            .accordion-button:not(.collapsed) {
              color: $second-color;
              background-color: $primary-color;
              box-shadow: none;
              font-family: "Poppins-Bold";
            }
            .accordion-button {
              text-transform: uppercase;
            }
            .accordion-button:not(.collapsed)::after {
              background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27><path fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/></svg>") !important;
            }

            .accordion-button::after {
              background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27><path fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/></svg>") !important;
            }

            .accordion-item {
              background-color: $primary-color;
              border-radius: 0;
              border-color: $primary-color;
              color: $second-color;
              font-size: $fonte-media;
              font-family: "Poppins-Bold";
              text-transform: uppercase;

              a {
                text-decoration: none;
              }

              li {
                list-style-type: none;
              }

              .icon {
                color: $second-theme-color;
              }
            }
          }
        }
      }
    }
  }
}


@media (min-width: 991.99px){
  #sessaoFooter {
    padding-bottom: 0;
    .footerRow {
      position: relative;
      padding-bottom: 10px;
      .iconesRodape {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
}