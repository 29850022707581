@import '../../themes/variables';

#ListagemCategorias {
  // color: $second-theme-color;

  padding: 80px 0 0 0;

  .carousel {
    .carouselLoading {
      aspect-ratio: 1;
    }
  }

  &.resumo {

    .containerImagemPrincipal,
    .containerImagemSecundaria {
      .categoria {
        position: relative;

        .imagemTitulo {
          font-family: Poppins-Bold;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 30px;
          font-size: 20px;
          color: white;
        }

        .sombreamento {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;

          background-image: linear-gradient(to top, transparent 0%, rgba(60, 60, 60, 0.666) 100%);
        }
      }
    }

    .containerImagemPrincipal {
      .categoria {
        .imagemPrincipalLoading {
          aspect-ratio: 1.15;
        }

        .imagemPrincipal {
          width: 100%;
          max-height: 800px;
          object-fit: cover;
          object-position: center;
        }
      }
    }

    .containerImagemSecundaria {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .link {
        height: calc(50% - 12px);

        .categoria {
          height: 100%;

          .imagemSecundariaLoading {
            aspect-ratio: 1.16;
          }

          .imagemSecundaria {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  #ListagemCategorias.resumo {
    .resumoBody {
      display: none;
    }

    .carousel-indicators {
      bottom: -50px;

      & [data-bs-target] {
        margin: 0 10px !important;
        height: 10px;
        width: 10px;
        background-color: $gray-color;
        border-radius: 50%;
      }

      & .active {
        background-color: $second-theme-color;
      }
    }

    .categoria {
      height: 350px;
      position: relative;

      p {
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: $fonte-exmedia;
        color: $second-color;
        font-weight: bold;
      }

      .img {
        width: 100%;
        height: 100%;
        padding: 0 !important;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }

  }

}

@media(min-width: $min-desktop) {
  #ListagemCategorias {
    .carousel {
      display: none;
    }

    .resumoBody {
      justify-content: center;
      height: calc((100vh - 130px));
      flex-direction: column;
      display: flex;
    }

    .imagemPrincipal {
      max-height: none !important;
    }

    .imagemPrincipal,
    .containerImagemSecundaria {
      height: calc((100vh - 230px));
    }
  }
}