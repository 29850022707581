@import '../../../themes/variables';

#sessaoContato {
  padding-top: 80px;

  .inputBox {
    input {
      font-family: 'Poppins-Regular';
      margin-bottom: 20px;
      border-radius: 0;
      box-shadow: none;
      text-decoration: none;
    }

    .box2 {
      textarea {
        height: 100%;
        box-shadow: none;
        border-radius: 0;
        font-family: 'Poppins-Regular';
      }
      padding-bottom: 20px;
    }
  }

  .ReCaptcha {
    display: flex;
    align-items: center;
    justify-content: left;
  }
  .enviar {
    font-family: 'Poppins-Bold';
    display: flex;
    justify-content: right;
    align-items: center;

    button {
      box-shadow: none;
      border: none;
      background-color: $second-theme-color;
      width: 300px;
      height: 50px;
      color: white;
      border-radius: 0;
    }
  }

  @media (max-width: 991.98px) {
    .ReCaptcha, .enviar {
      justify-content: center;
    }
  }
}