#ImagemViewerZoom {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  align-items: center;

  .img {
    height: 80vh;
    width: 80vw;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}

.sobreNosModal {

  .img {
    height: 80vh;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  .carousel.slide {
    width: 90vw;
  }

  .carousel-indicators {
    bottom: -50px;
  }

  .modal-backdrop {
    background-color: transparent;
  }

  .modal-content {
    background-color: rgba($color: #000000, $alpha: 0.9);

    .modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .closeModal {
      fill: whitesmoke;
      position: absolute;
      right: 30px;
      top: 30px;
      z-index: 1100;
      cursor: pointer;
    }

  }
}