@import '../../themes/variables';

#paginaNaoEncontrada {

  .ancora {
    height: 130px;
  }

  .container {
    padding: 80px 0;

    .titulo {
      margin-bottom: 30px;
    }
    .botao{
      width: 300px;
      height: 50px;
      background-color: $second-theme-color;
      border: $second-theme-color 2px solid;
      box-shadow: none;
      &:hover{
        background-color: $theme-color;
        color: $second-theme-color;
      }
    }
  }

}