@import '../../themes/variables';

#menuTabela {
    color: $second-theme-color;

    .tabela {
        display: flex;
        justify-content: flex-end;
        font-size: $fonte-pequena;

        .selected {
            font-family: 'Poppins-Bold'!important;
        }

        .categoria, .titulo {
            width: 100%;
            font-family: 'Poppins-Regular';
            color: $primary-color;
            text-decoration: none;
        }
    
        .titulo {
            border-bottom: 1px solid $third-theme-color;
            padding-bottom: 0.5rem;
            margin-bottom: 5px;
            text-transform: uppercase;
            font-family: 'Poppins-Bold';
        }
        .categoria {
            padding-top: 0.3rem;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
    
            &:hover {
                font-family: 'Poppins-Bold'!important;
            }
    
            .nome {
            }
    
            .qtd {
            }
        }
    }
}

@media (max-width: 991.98px){
    #menuTabela .tabela .tabelaCol {
        padding: 0;
        font-size: $fonte-exmedia;
    } 
}




// Skeletons 

#menuTabela {
    .skeletonTabelaContainer {
        width: 100%;
        height: 200px;
    }
}