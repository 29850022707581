@import "../../themes/variables";

#modalCadastroNewsletter {
  position: absolute;
  top: -80px;
  right: 0;
  left: 0;

  .containerNewsletter {
    background-color: $second-theme-color;
    padding: 30px !important;
    border-radius: 10px;

    .row {
      margin: auto;
    }

    .itemNewsletter {
      align-items: center;
      display: inline-grid;

      .colInputNewsletter {
        font-family: 'Poppins-Regular';
        padding-right: 0;
      }

      .colBtnNewsletter {
        font-family: 'Poppins-Bold';
        display: flex;
        padding: 0;

        .grupoBtn {
          width: 100%;
        }
      }

      .mensagem {
        position: absolute;
        color: white;
        font-weight: bold;
      }

      .textoNewsletter {
        font-family: 'Poppins-Bold';
        font-size: 22px;
        color: $theme-color;
        margin-bottom: 0;
      }

      .form-control {
        line-height: 2.5;
        border: none;
        border-radius: 0;
        z-index: 1;
      }

      .btn {
        border-radius: 0;
        background-color: #236e45;
        width: 100%;
        height: 100%;
        color: $second-color;
        border: transparent !important;
      }
    }
  }
}

@media (max-width: 991.98px) {
  #modalCadastroNewsletter {
    padding: 10px;
    position: relative;

    .containerNewsletter {
      padding: 15px 0 !important;
      .itemNewsletter {
        .colInputNewsletter {
          padding: 0;
        }

        .textoNewsletter {
          font-size: $fonte-grande;
          margin-bottom: 15px;
        }

        .form-control {
          font-size: $fonte-grande;
        }
        .colBtnNewsletter {
          display: block !important;
        }
        .btn {
          margin-top: 15px;
          width: 100% !important;
          line-height: 28px !important;
          font-size: $fonte-grande;
        }
      }
    }
  }
}