@import "../../../themes/variables";

#sessaoListagemBlogs {
  padding-top: 80px;
  color: $second-theme-color;

  &.resumo {
    .titulo {
      font-family: 'Poppins-Bold';
      padding-bottom: 2rem;

      .tag {
        height: 30px;
        width: 6px;
        background-color: $second-theme-color;
      }

      span {
        font-size: $fonte-gigante;
        color: $primary-color;
      }
    }
  }

  &.normal {
    @media (max-width: 991.98px) {
      .listagemBox {
        order: 2;
      }

      .tabelaBox {
        order: 1;
        margin-bottom: 2rem;
      }
    }
  }
}

@media (min-width: $min-desktop) {
  #sessaoListagemBlogs {
    &.resumo {
      margin-top: 80px;
      padding-top: 40px;
      padding-bottom: 40px;
      .titulo.container {
        margin-bottom: 1rem;
      }
    }
  }
}

@media (max-width: 991.98px) {
  #sessaoListagemBlogs {
    padding-bottom: 80px;
  }
}