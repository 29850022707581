@import "../../../themes/variables";

#sessaoInspiracoes {
  color: $primary-color;
  padding-top: 80px;
  min-height: 100vh;
  
  .options {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      cursor: pointer;
      border: none;
      padding: 5px 15px;
      margin: 0 20px;
      color: $primary-color;
      background-color: $second-color;
      font-family: 'Poppins-Bold';

      &.selected {
        border-radius: 30px;
        background-color: $second-theme-color;
        color: $second-color;
      }
    }
  }

  .imagens {
    padding-top: 50px;
    .imagem {
      cursor: pointer;
      width: 100%;
      margin-bottom: 20px;
      img {
        width: 100%;
      }
    }
  }

  .carregarMais {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;

    button {
      cursor: pointer;
      border: 2px solid $second-theme-color;
      background-color: $second-color;
      color: $second-theme-color;
      font-family: 'Poppins-Bold';
      font-size: $fonte-media;
      padding: 5px 10px;
    }
  }

  .accordion {
    background-color: transparent;
    .accordion-item {
      border: none;
      background-color: transparent;

      .accordion-header {
        border-bottom: 1px solid gray;
        margin-bottom: 10px;

        button {
          color: $primary-color;
          background-color: transparent;
          border-radius: 0;
          display: flex;
          justify-content: space-between;
          box-shadow: none;
          padding-left: 0px;
          padding-right: 0px;
          text-transform: uppercase;

          &.collapsed {
            .up {
              display: none;
            }
          }

          &:not(.collapsed) {
            .down {
              display: none;
            }
          }

          &::after {
            display: none;
          }

          margin-bottom: 10px;

        }
      }

      .accordion-collapse {
        .accordion-body {
          padding: 15px 0;
          color: $primary-color;
          button {
            border: none;
            background-color: transparent;
            width: 100%;
            text-align: left;
            margin-bottom: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }

  // --------- skeletons -----------

  .skeletonImagens {
    padding-top: 50px;
    .imgSkeleton {
      margin-bottom: 20px;
      margin-bottom: 20px;
      width: 100%;
    }
  }
  
  .skeletonOptions {
    display: flex;
    justify-content: center;

    div {
      width: 80%;
      height: 50px;
    }
  }

  @media (max-width: 991.98px) {
    .skeletonOptions {
      div {
        width: 100%;
      }
    }
    .carregarMais{
      padding-bottom: 80px;
    }
  }

}